
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import store from "@/store";

export default defineComponent({
  name: "underwriter-policy-terms-and-conditions",
  components: {},
  props: {
    redirect: String,
    policyRoute: String,
    policiesRoute: String,
    clientsRoute: String,
    role: String,
  },
  data() {
    return {
      selectedTermIndex: 0,
      policies: [],
      vehicles: [],
      policySummary: {},
      shortCode: "",
      displayTable: false,
    };
  },
  setup() {
    const user: object = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Terms and Conditions", ["Policies"]);
      MenuComponent.reinitialization();
    });

    return { user };
  },
  created() {
    //Set page title
    document.title =
      "Policy Terms and Conditions | " + process.env.VUE_APP_TITLE;
  },
});
